<template>
  <div id="pageLinha">
    <v-container grid-list-x2 fluid>
      <v-layout row wrap justify-center>
        <v-flex lg8 sm12 xs12>
          <linha-table></linha-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import LinhaTable from "@/components/linha/tableLinha"
export default {
  components: {
    LinhaTable
  }
}
</script>
